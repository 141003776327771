/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Fullmap, FullmapWrap, FullmapCover, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Penzion"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-cwpt5j css-144ko4g --style2 --full pb--60 pt--60" name={"v01kxbrzml"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/0e0ff89c1f814ce8a8718f4df0068a32_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/0e0ff89c1f814ce8a8718f4df0068a32_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/0e0ff89c1f814ce8a8718f4df0068a32_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/0e0ff89c1f814ce8a8718f4df0068a32_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/0e0ff89c1f814ce8a8718f4df0068a32_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/0e0ff89c1f814ce8a8718f4df0068a32_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/0e0ff89c1f814ce8a8718f4df0068a32_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/0e0ff89c1f814ce8a8718f4df0068a32_s=3000x_.jpeg);
    }
  
background-position: 45% 37%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--86 title-box--invert" content={"Penzion<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(252,251,250,1)"}} name={"8267ghbx5b5"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":128}} src={"https://cdn.swbpg.com/o/11212/0842d7c03d8e4c449b8a94021d47cd32.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={""}>
              </Image>

              <Subtitle className="subtitle-box" content={"Wi-fi zdarma"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":128}} src={"https://cdn.swbpg.com/o/11212/2f2052f43ac84446a1080cb97567d07a.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={""}>
              </Image>

              <Subtitle className="subtitle-box" content={"Parkování zdarma"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":150}} src={"https://cdn.swbpg.com/o/11212/0f9549b5ce4f406d97b2b36d208f07ac.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={""}>
              </Image>

              <Subtitle className="subtitle-box" content={"Možnost 1–2 přistýlek"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":128}} src={"https://cdn.swbpg.com/o/11212/5b03da564b7a4c9182b2288e90efb25f.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={""}>
              </Image>

              <Subtitle className="subtitle-box" content={"Koupelna na pokoji"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"informace-1"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2 flex--top" style={{"maxWidth":""}} anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}} animS={"3"}>
              
              <Title className="title-box lh--12" style={{"maxWidth":500}} content={"Kapacita penzionu je 10 pokojů, <br>max. 38 lůžek."}>
              </Title>

              <Text className="text-box mt--10 pl--0 pr--0" content={" (20 pevných lůžek + 18 přistýlek)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}} animS={"3"}>
              
              <Text className="text-box text-box--left mt--06 pl--0 pr--0" content={"✅ Ubytování ve 2 lůžkových pokojích (totožných) s možností 1 přistýlky (jsou možné i 2 přistýlky, ale v tomto případě jsou prostory pokoje značně omezené). Součástí každého pokoje je sociální zařízení (WC, sprchový kout), stolek + 2 židle, šatní skříň s malým botníkem a lednice. Povlečení a ručník včetně."}>
              </Text>

              <Text className="text-box text-box--left mt--06 pl--0 pr--0" content={"✅ Wi-Fi v celém areálu.\n\n"}>
              </Text>

              <Text className="text-box text-box--left mt--06 pl--0 pr--0" content={"✅ Posezení na terase/balkóně.\n\n"}>
              </Text>

              <Text className="text-box text-box--left mt--06 pl--0 pr--0" content={"✅ Prostorná společenská místnost s TV. \n\n"}>
              </Text>

              <Text className="text-box text-box--left mt--06 pl--0 pr--0" content={"✅ Plně vybavená společná kuchyně (lednice, varný panel, mikrovlnná a elektrická trouba, myčka). Stravování vlastní.\n\n"}>
              </Text>

              <Text className="text-box text-box--left mt--06 pl--0 pr--0" content={"✅ Úschova kol a parkování u penzionu (bez poplatku). Celý areál je oplocen včetně parkoviště a zabezpečen kamerovým systémem."}>
              </Text>

              <Text className="text-box text-box--left mt--06 pl--0 pr--0" content={"✅ Posezení pod pergolou (venkovní krb, velký plynový gril, ohniště, zásoba dřeva). U pergoly (vně penzionu) je k dispozici druhá, menší kuchyňka i s posezením). \n\n\n\n"}>
              </Text>

              <Text className="text-box text-box--left mt--06 pl--0 pr--0" content={"✅ Malé zázemí pro děti - skluzavka, houpačka, pískoviště."}>
              </Text>

              <Text className="text-box text-box--left mt--06 pl--0 pr--0" content={"✅ Degustace v klasickém moravském sklepě pouze pro ubytované (po dohodě, stání u \"beček\" - sklep není přizpůsoben na posezení a není možnost občerstvení). Sklep není součástí budovy vinařství, nachází se na ulici Sklepní u rybníka."}>
              </Text>

              <Text className="text-box text-box--left mt--06 pl--0 pr--0" content={"✅&nbsp;Ideální místo především pro partu známých a přátel, kteří milují cykloturistiku a dobré moravské víno. Penzion stojí samostatně ve svahu nad Strachotínem. Naší zahradou se snadno dostanete zkratkou na ulici Sklepní u rybníka a do dědiny."}>
              </Text>

              <Text className="text-box text-box--left mt--06 pl--0 pr--0" content={"✅ Prodej moravských vín vlastní výroby.\n\n"}>
              </Text>

              <Text className="text-box text-box--left mt--06 pl--0 pr--0" content={"✅&nbsp;Bez zvířecích mazlíčků."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(30,30,35,1)"}} name={"cenik"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\">Ceník ubytování</span>"}>
              </Title>

              <Text className="text-box mt--10" content={"<span style=\"color: rgb(191, 172, 152);\">&nbsp;2024</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":1200}} columns={"3"}>
            
            <ColumnWrapper className="pb--40 pt--30" style={{"maxWidth":800,"backgroundColor":"rgba(37,37,42,1)"}}>
              
              <Title className="title-box fs--36" content={"<span style=\"color: rgb(255, 255, 255);\">Pevné lůžko/noc</span><br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: rgb(191, 172, 152);\">5 a více nocí</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--26 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">650 Kč</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(191, 172, 152);\">&nbsp;3-4 noci</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--26 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">700 Kč</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(191, 172, 152);\">2 noci</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--26 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">750 Kč</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(191, 172, 152);\">1 noc</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--18 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">pouze pro skupinu od 20-ti osob, na dotaz</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="pb--40 pt--30" style={{"maxWidth":800,"backgroundColor":"rgba(37,37,42,1)"}}>
              
              <Title className="title-box fs--36" content={"<span style=\"color: rgb(255, 255, 255);\">Přistýlka/noc</span><br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: rgb(191, 172, 152);\">5 a více nocí</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--26 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">500&nbsp; Kč</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(191, 172, 152);\">3-4 noci&nbsp;</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--26 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">550 Kč</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(191, 172, 152);\">2 noci</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--26 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">600 Kč</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(191, 172, 152);\">1 noc</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--18 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">pouze pro skupinu od 20-ti osob, na dotaz</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="pb--40 pt--30" style={{"maxWidth":800,"backgroundColor":"rgba(37,37,42,1)"}}>
              
              <Title className="title-box fs--36" content={"<span style=\"color: rgb(255, 255, 255);\">Poplatek obci/noc</span><br>"}>
              </Title>

              <Text className="text-box" content={"<br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--26 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">20 Kč</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--26 mt--06" content={"<br>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--26 mt--06" content={"<br>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--26 mt--06" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center fs--14" content={"<span style=\"color: rgb(255, 255, 255);\">* Ceny uvedeny za osobu/noc (od 2 let), dítě do 2 let bez nároku na lůžko 150 Kč/noc (dětské postýlky a židličky nemáme k dispozici).<br>* Ceny uvedeny bez poplatku obci.<br>* Ceny uvedeny bez topení (účtujeme příplatek dle skutečné spotřeby). <br>* V případě pouze 1 noci nebo pouze 1 osoby na pokoji výjimečně a po domluvě.<br>* Konkrétní cenovou nabídku zpracujeme na základě Vaší poptávky.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"tu12amoiuio"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/aa85389c217348ee8b37d30232a5f0b8_s=660x_.jpg"} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/aa85389c217348ee8b37d30232a5f0b8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/aa85389c217348ee8b37d30232a5f0b8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/aa85389c217348ee8b37d30232a5f0b8_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box fs--20" content={"<span style=\"font-weight: bold;\">Dvoulůžkový pokoj</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/b7dc57df3db347f1ac58fdddf83b0660_s=660x_.jpg"} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/b7dc57df3db347f1ac58fdddf83b0660_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/b7dc57df3db347f1ac58fdddf83b0660_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/b7dc57df3db347f1ac58fdddf83b0660_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Společná kuchyně</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/cc78af211a8046bf8fcf6dc307e8bca3_s=860x_.JPEG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/cc78af211a8046bf8fcf6dc307e8bca3_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/19433/cc78af211a8046bf8fcf6dc307e8bca3_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/19433/cc78af211a8046bf8fcf6dc307e8bca3_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/19433/cc78af211a8046bf8fcf6dc307e8bca3_s=1400x_.JPEG 1400w, https://cdn.swbpg.com/t/19433/cc78af211a8046bf8fcf6dc307e8bca3_s=2000x_.JPEG 2000w"}>
              </Image>

              <Title className="title-box fs--20" content={"<span style=\"font-weight: bold;\">Romantické zákoutí na zahradě</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/3f8eff4ba3764025b4676088c7e9e39c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/3f8eff4ba3764025b4676088c7e9e39c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/3f8eff4ba3764025b4676088c7e9e39c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/3f8eff4ba3764025b4676088c7e9e39c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/3f8eff4ba3764025b4676088c7e9e39c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/3f8eff4ba3764025b4676088c7e9e39c_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Druhá kuchyně u pergoly</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/64e2d38b3864469abe57d8525327f57a_s=860x_.JPEG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/64e2d38b3864469abe57d8525327f57a_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/19433/64e2d38b3864469abe57d8525327f57a_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/19433/64e2d38b3864469abe57d8525327f57a_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/19433/64e2d38b3864469abe57d8525327f57a_s=1400x_.JPEG 1400w, https://cdn.swbpg.com/t/19433/64e2d38b3864469abe57d8525327f57a_s=2000x_.JPEG 2000w"}>
              </Image>

              <Title className="title-box fs--20" content={"<span style=\"font-weight: bold;\">Společenská místnost s TV</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/a8b5e5bd38fb4f58b298f32c7e63eb8d_s=660x_.jpg"} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/a8b5e5bd38fb4f58b298f32c7e63eb8d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/a8b5e5bd38fb4f58b298f32c7e63eb8d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/a8b5e5bd38fb4f58b298f32c7e63eb8d_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Posezení na terase</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/9d7814d70fd44152b260ea9bc9ce6191_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/9d7814d70fd44152b260ea9bc9ce6191_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/9d7814d70fd44152b260ea9bc9ce6191_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/9d7814d70fd44152b260ea9bc9ce6191_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/11212/9d7814d70fd44152b260ea9bc9ce6191_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/11212/9d7814d70fd44152b260ea9bc9ce6191_s=2000x_.jpeg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Pokoj se 2-mi přistýlkami</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/dc452db6c4364b8a9e181d75ca44f762_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/dc452db6c4364b8a9e181d75ca44f762_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/dc452db6c4364b8a9e181d75ca44f762_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/dc452db6c4364b8a9e181d75ca44f762_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/dc452db6c4364b8a9e181d75ca44f762_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/dc452db6c4364b8a9e181d75ca44f762_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Společná plně vybavená kuchyně</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/1ddfd24620f547ffb7c3be908666e383_s=860x_.JPEG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/1ddfd24620f547ffb7c3be908666e383_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/19433/1ddfd24620f547ffb7c3be908666e383_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/19433/1ddfd24620f547ffb7c3be908666e383_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/19433/1ddfd24620f547ffb7c3be908666e383_s=1400x_.JPEG 1400w, https://cdn.swbpg.com/t/19433/1ddfd24620f547ffb7c3be908666e383_s=2000x_.JPEG 2000w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Zahrada</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/2b25be321fe94ee7b6294f54eddcea23_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/2b25be321fe94ee7b6294f54eddcea23_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/2b25be321fe94ee7b6294f54eddcea23_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/2b25be321fe94ee7b6294f54eddcea23_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Vstup do penzionu s parkovištěm</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/69b2f116009646b59886bdc37845685a_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/69b2f116009646b59886bdc37845685a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/69b2f116009646b59886bdc37845685a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/69b2f116009646b59886bdc37845685a_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Pohled ze zahrady</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/55cb561238db4b1fba56726d030fbdb4_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/55cb561238db4b1fba56726d030fbdb4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/55cb561238db4b1fba56726d030fbdb4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/55cb561238db4b1fba56726d030fbdb4_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Vinařství &amp; Penzion stojí ve svahu nad Strachotínem</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/eaa5d2f035624b22954081dcbe9985ea_s=660x_.jpg"} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/eaa5d2f035624b22954081dcbe9985ea_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/eaa5d2f035624b22954081dcbe9985ea_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/eaa5d2f035624b22954081dcbe9985ea_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Pohled ze zahrady</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/850e0de9cf524674b8e83d36142dd60e_s=860x_.jpg"} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/850e0de9cf524674b8e83d36142dd60e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/850e0de9cf524674b8e83d36142dd60e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/850e0de9cf524674b8e83d36142dd60e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/850e0de9cf524674b8e83d36142dd60e_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Na každém pokoji sociální zařízení</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/19433/68119feb50e441cfa160c058cc3d4185_s=860x_.JPEG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19433/68119feb50e441cfa160c058cc3d4185_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/19433/68119feb50e441cfa160c058cc3d4185_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/19433/68119feb50e441cfa160c058cc3d4185_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/19433/68119feb50e441cfa160c058cc3d4185_s=1400x_.JPEG 1400w, https://cdn.swbpg.com/t/19433/68119feb50e441cfa160c058cc3d4185_s=2000x_.JPEG 2000w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Posezení pod pergolou se sklenkou našeho vína</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/cfedf544f4d14b2c921c831246da394e_s=860x_.JPEG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/cfedf544f4d14b2c921c831246da394e_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/19433/cfedf544f4d14b2c921c831246da394e_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/19433/cfedf544f4d14b2c921c831246da394e_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/19433/cfedf544f4d14b2c921c831246da394e_s=1400x_.JPEG 1400w, https://cdn.swbpg.com/t/19433/cfedf544f4d14b2c921c831246da394e_s=2000x_.JPEG 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19433/93c6c5e9001349439ac14c588effe110_s=860x_.JPEG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/93c6c5e9001349439ac14c588effe110_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/19433/93c6c5e9001349439ac14c588effe110_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/19433/93c6c5e9001349439ac14c588effe110_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/19433/93c6c5e9001349439ac14c588effe110_s=1400x_.JPEG 1400w, https://cdn.swbpg.com/t/19433/93c6c5e9001349439ac14c588effe110_s=2000x_.JPEG 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19433/6da1dec7a30b4c818356c40defcdc4d6_s=860x_.JPEG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/6da1dec7a30b4c818356c40defcdc4d6_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/19433/6da1dec7a30b4c818356c40defcdc4d6_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/19433/6da1dec7a30b4c818356c40defcdc4d6_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/19433/6da1dec7a30b4c818356c40defcdc4d6_s=1400x_.JPEG 1400w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">U nás se neztratíte</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/ec93ed423d3247319a951c11221d075c_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/ec93ed423d3247319a951c11221d075c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/ec93ed423d3247319a951c11221d075c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/ec93ed423d3247319a951c11221d075c_s=860x_.jpg 860w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19433/de3387e7f65849669eba681752b777cc_s=860x_.JPEG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/de3387e7f65849669eba681752b777cc_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/19433/de3387e7f65849669eba681752b777cc_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/19433/de3387e7f65849669eba681752b777cc_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/19433/de3387e7f65849669eba681752b777cc_s=1400x_.JPEG 1400w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Pohled ze zahrady</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/2ec54474ff724bd9b31e8e7ab8a0327d_s=860x_.JPEG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/2ec54474ff724bd9b31e8e7ab8a0327d_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/19433/2ec54474ff724bd9b31e8e7ab8a0327d_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/19433/2ec54474ff724bd9b31e8e7ab8a0327d_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/19433/2ec54474ff724bd9b31e8e7ab8a0327d_s=1400x_.JPEG 1400w, https://cdn.swbpg.com/t/19433/2ec54474ff724bd9b31e8e7ab8a0327d_s=2000x_.JPEG 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19433/70c00c223e3f409ebf019a744cc459ee_s=860x_.JPEG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/70c00c223e3f409ebf019a744cc459ee_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/19433/70c00c223e3f409ebf019a744cc459ee_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/19433/70c00c223e3f409ebf019a744cc459ee_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/19433/70c00c223e3f409ebf019a744cc459ee_s=1400x_.JPEG 1400w"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">Krásné...co říkáte?</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"kufbcwm7nka"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap className="--style2" name={"jd79ej1nedd"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover place={"Brno"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--50 pt--50" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" style={{"maxWidth":""}} anim={""} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box subtitle-box--left mt--06 pl--0" content={"<span style=\"color: rgb(255, 255, 255);\">VINAŘSTVÍ KNÁPEK</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left mt--02" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">s.r.o.</span>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":""}} content={"<span style=\"color: rgb(255, 255, 255);\">Pouzdřanská 340<br>693 01 Strachotín<br>IČO 05868751<br>DIČ CZ05868751</span><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"https://tvorba-stranek-brno.cz/\">Tvorba webových stránek Brno</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":485}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Vinařství — Libor Knápek</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left ls--0 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">(420) 777 574 207</span><span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Ubytování — Jana Knápková</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left ls--0 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">(420) 608 423 866</span><span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"mailto:info@ubytovani-strachotin-knapek.cz\">info@ubytovani-strachotin-knapek.cz</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left mt--0" style={{"maxWidth":366}} content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"https://www.facebook.com/VinarstviKnapek/\">https://www.facebook.com/VinarstviKnapek/</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2" use={"page"} content={"Úvod"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} content={"Ubytování"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} content={"Vinní sklep"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} content={"Ceník"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}